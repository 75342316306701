import type {Iri, UrlId} from '../@types/Api'
import anyBase from 'any-base'

// Use Symfony's alphabet
const alphabet = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'

const fromHex = anyBase(anyBase.HEX, alphabet)
const toHex = anyBase(alphabet, anyBase.HEX)

const converter = {
  // converts long UUID to short UUID
  fromUUID: (longId: string): string => {
    return fromHex(longId.toLowerCase().replace(/-/g, ''))
  },
  toUUID: (shortId: string): string => {
    const uu1 = toHex(shortId).padStart(32, '0')

    return [
      uu1.substring(0, 8),
      uu1.substring(8, 12),
      uu1.substring(12, 16),
      uu1.substring(16, 20),
      uu1.substring(20),
    ].join('-')
  },
}

export function iriToId<T extends Iri | undefined>(iri: T): T extends undefined ? undefined : UrlId {
  if (!iri) {
    return undefined as T extends undefined ? undefined : never
  }

  return converter.fromUUID(parseIri(iri)[1]) as unknown as T extends undefined ? never : UrlId
}

export function iriToUuid(iri: Iri): string {
  return parseIri(iri)[1]
}

function parseIri(iri: Iri): [string, string] {
  // @ts-ignore
  return iri.split(/\//g).slice(-2)
}

export function createItemIri<T extends UrlId | undefined>(resource: string, urlId: T): T extends undefined ? undefined : Iri {
  if (urlId === undefined) {
    return undefined as T extends undefined ? undefined : never
  }

  try {
    return `/${resource}/${converter.toUUID(urlId)}` as T extends undefined ? never : Iri
  } catch {
    return `/${resource}/00000000-0000-0000-0000-000000000000` as T extends undefined ? never : Iri
  }
}

export function createCollectionIri(resource: string): Iri {
  return `/${resource}`
}
