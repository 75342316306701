import type {ReactNode} from 'react'
import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai/index'
import type {Setter} from '../@types/setter'

export type SeverityType = 'info' | 'warning' | 'error' | 'success'

export type NotificationType = {
  key: string
  severity: SeverityType
  message: ReactNode
}
type NotificationStack = Record<string, NotificationType>

const notificationStackAtom = atom<NotificationStack>({})

export function useStateNotificationStack(): [NotificationStack, Setter<NotificationStack>] {
  return useAtom(notificationStackAtom)
}

export function useNotificationStack(): NotificationStack {
  return useAtomValue(notificationStackAtom)
}

export function useSetNotificationStack(): Setter<NotificationStack> {
  return useSetAtom(notificationStackAtom)
}
