import {useEffectEvent} from './useEffectEvent'
import type {ReactNode} from 'react'
import {type SeverityType, useSetNotificationStack} from '../states/NotificationStack'

async function renderToString(element: ReactNode) {
  const s = (await import('react-dom/server')).default
  return s.renderToString(element)
}

interface Hook {
  readonly notifySuccess: (message: ReactNode) => void
  readonly notifyError: (message: ReactNode) => void
  readonly notifyInfo: (message: ReactNode) => void
}

export function useNotification(): Hook {
  const setNotificationStack = useSetNotificationStack()

  const notify = useEffectEvent(async (message: ReactNode, severity: SeverityType): Promise<void> => {
    const key = typeof message === 'string' ? message : await renderToString(message)
    setNotificationStack((previous) => ({
      ...previous,
      [key]: {
        key,
        severity,
        message,
      },
    }))
  })
  const notifySuccess = useEffectEvent((message: ReactNode): void => {
    notify(message, 'success')
  })
  const notifyError = useEffectEvent((message: ReactNode): void => {
    notify(message, 'error')
  })
  const notifyInfo = useEffectEvent((message: ReactNode): void => {
    notify(message, 'info')
  })

  return {
    notifyInfo,
    notifyError,
    notifySuccess,
  }
}
