import {lazy, type PropsWithChildren, type ReactNode} from 'react'

const NotificationStackContainer = lazy(async () => ({default: (await import('../components/NotificationStackContainer')).NotificationStackContainer}))

export const NotificationStackProvider = ({
  children,
}: PropsWithChildren): ReactNode => {
  return <>
    {children}
    <NotificationStackContainer/>
  </>
}
